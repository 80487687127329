import isEqual from 'lodash/isEqual'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { Template } from './../../../graphql/__generated__/types'

interface UseDirtyTemplate {
  isDirty: boolean
  currentTemplate: Template
  resetOriginalTemplate: (template: Template) => void
  onCancel: () => void
  updateTemplate: (template: Template) => void
}

export function useDirtyTemplate(initTemplate: Template): UseDirtyTemplate {
  const { clearErrors } = useFormContext()
  const [originalTemplate, setOriginalTemplate] = useState<Template>(initTemplate)
  const [latestTemplate, setLatestTemplate] = useState<Template>(initTemplate)

  const isNewTemplate = !latestTemplate.id
  const isDirty
    = !isEqual(originalTemplate.revision.configuration, latestTemplate.revision.configuration)
    || originalTemplate.name !== latestTemplate.name
    || isNewTemplate

  return {
    isDirty,
    resetOriginalTemplate: (template: Template) => {
      setOriginalTemplate(template)
    },
    onCancel: () => {
      setLatestTemplate(originalTemplate)
      clearErrors()
    },
    updateTemplate: (template: Template) => {
      setLatestTemplate(template)
    },
    currentTemplate: latestTemplate,
  }
}

import React, { useState, useEffect } from 'react'
import { useDebounce } from 'use-debounce'
import {
  OrgAddresses,
  useUpdateAddressNicknameByIdMutation,
  useAddNewAddressMutation,
  useSearchAddressesLazyQuery,
  OrgAddressesBoolExp,
} from 'graphql/__generated__/hasura-types'
import { SolSearchDropdown } from 'SolComponents'
import { BaseAddress } from 'pages/PodConfig/SettingsCards/Map/MapsTypes'
import { DropDownOption } from 'SolComponents/SolSearchDropdown/SolSearchDropdown'
import { EditAddressModal } from './EditAddMode/EditAddressModal'
import { AddGoogleAddress } from './EditAddMode/AddGoogleAddress'
import { useLocation } from 'react-router'
import { BulkProps } from 'SolComponents/SolDropdown/SolDropdown'
import { useDeleteAddress } from '../../../shared/hooks/management/useAddress'
import { DeleteAddressModal } from './DeleteMode/DeleteAddressModal'

export const useKnownBuildings = (
  shouldQuery: boolean,
  searchTerm: string,
  excludeIds: number[],
  mapBounds?: number[][],
) => {
  const [searchValue] = useDebounce(searchTerm, 300)

  const where: OrgAddressesBoolExp = {
    id: {
      _nin: excludeIds,
    },
    _or: [{ address_full: { _ilike: `%${searchValue}%` } }, { nickname: { _ilike: `%${searchValue}%` } }],
  }

  const locationWhere: OrgAddressesBoolExp = {
    id: {
      _nin: excludeIds,
    },
    precise_location: {
      _st_intersects: {
        type: 'Polygon',
        coordinates: [mapBounds],
      },
    },
  }

  const [fetchAddresses, { data }] = useSearchAddressesLazyQuery({
    variables: {
      where: mapBounds !== undefined && searchValue.length === 0 ? locationWhere : where,
    },
    pollInterval: 5000,
  })

  useEffect(() => {
    if (shouldQuery) {
      fetchAddresses()
    }
  }, [searchTerm, shouldQuery])

  return data?._addresses ?? []
}

function mapAddressToDropdownItem(
  address: Omit<BaseAddress, 'floors' | 'floors_aggregate'>,
  editable?: boolean,
): DropDownOption {
  return {
    key: address.id,
    value: address.nickname || address.building,
    text: address.nickname || address.building,
    description: address.state_province
      ? address.building + ', ' + address.city + ', ' + address.state_province
      : address.building + ', ' + address.city,
    editable: editable ?? false,
  }
}

type State = {
  searchValue?: string
  dropdownIsOpen: boolean
}

type AddressToDelete = {
  description: string
  editable: boolean
  key: number
  text: string
  value: string
}

/*
  This component handles
    - adding a new address
    - editing an existing address
    - searching for existing addresses
    - calling back to client - onSelect
*/

export const BuildingsDropdown = ({
  onSelectAddress,
  address,
  onClick,
  label,
  size,
  variant,
  hasUnknownBuilding,
  mapBounds,
  bulk,
  tooltipText,
}: {
  onSelectAddress: (address: BaseAddress) => void
  address?: BaseAddress | null
  onClick?: () => void
  label?: string
  size: 'fluid' | 'static' | 'fixed'
  variant: 'single-view' | 'table-view' | 'new-location-view'
  hasUnknownBuilding?: boolean
  mapBounds?: number[][]
  bulk?: BulkProps
  tooltipText?: string
}) => {
  const [state, setState] = useState<State>({
    dropdownIsOpen: false,
  })
  const [editAddressModalIsOpen, setEditAddressModalIsOpen] = useState<boolean>(false)
  const [deleteAddressModalIsOpen, setDeleteAddressModalIsOpen] = useState<boolean>(false)
  const [addAddressModalIsOpen, setAddAddressModalIsOpen] = useState<boolean>(false)
  const [addressToDelete, setAddressToDelete] = useState<AddressToDelete|null>(null)

  const location = useLocation()
  useEffect(() => {
    setEditAddressModalIsOpen(false)
    setDeleteAddressModalIsOpen(false)
    setAddAddressModalIsOpen(false)
  }, [location])

  const addresses = useKnownBuildings(
    state.dropdownIsOpen,
    state.searchValue ?? '',
    address ? [address.id] : [],
    mapBounds,
  )
  const [updateNickname] = useUpdateAddressNicknameByIdMutation()
  const [saveNewAddress] = useAddNewAddressMutation()
  const { deleteAddress } = useDeleteAddress()
  let showCurrentAddress = !state.searchValue
  const currentAddress = address && showCurrentAddress
    ? [mapAddressToDropdownItem(address, true)]
    : []

  const allAddresses = currentAddress.concat(
    addresses.filter(a => a.id !== address?.id).map(a => mapAddressToDropdownItem(a)),
  )
  return (
    <>
      <SolSearchDropdown
        isOpen={state.dropdownIsOpen}
        options={allAddresses}
        value={state.searchValue ?? (address?.nickname || address?.building)}
        pinnedButtonText="ADD NEW BUILDING"
        label={label ?? ''}
        questionIcon
        size={size}
        variant={variant}
        hasError={hasUnknownBuilding}
        placeholder={hasUnknownBuilding && variant === 'table-view' && !state.dropdownIsOpen ? 'Unknown' : undefined}
        onClick={() => {
          setState({
            searchValue: '',
            dropdownIsOpen: true,
          })
          onClick?.()
        }}
        onBlur={() => {
          setState({
            dropdownIsOpen: false,
          })
        }}
        onSearchChange={searchValue => {
          setState({
            searchValue,
            dropdownIsOpen: true,
          })
        }}
        onOpen={() => {
          setState({
            dropdownIsOpen: true,
          })
          onClick?.()
        }}
        onClose={() => {
          setState({
            dropdownIsOpen: false,
          })
        }}
        pinnedButtonClick={() => {
          setAddAddressModalIsOpen(true)
        }}
        onSelect={val => {
          onSelectAddress(addresses.find(o => o.id === val.key) as OrgAddresses)
          setState({
            dropdownIsOpen: false,
          })
        }}
        editPencilClick={() => {
          setEditAddressModalIsOpen(true)
        }}
        deleteAddressClick={(selectedAddress: AddressToDelete) => {
          setAddressToDelete(selectedAddress)
          setDeleteAddressModalIsOpen(true)
        }}
        bulk={bulk}
        tooltipText={tooltipText}
      />
      {addAddressModalIsOpen && (
        <AddGoogleAddress
          onClose={() => {
            setAddAddressModalIsOpen(false)
          }}
          onAccept={async data => {
            const newAddress = await saveNewAddress({
              variables: {
                object: data,
              },
            })
            onSelectAddress({
              ...data,
              id: newAddress.data!.insert_org_addresses_one!.id,
            })
            setAddAddressModalIsOpen(false)
          }}
          isOpen={addAddressModalIsOpen}
          dropdownClick={() => onClick?.()}
        />
      )}
      {editAddressModalIsOpen && (
        <EditAddressModal
          currentAddress={address!}
          isOpen={editAddressModalIsOpen}
          onClose={() => {
            setEditAddressModalIsOpen(false)
          }}
          onAccept={async data => {
            await updateNickname({
              variables: { id: data.id, nickname: data.nickname },
            })
            setEditAddressModalIsOpen(false)
          }}
        />
      )}
      {deleteAddressModalIsOpen && (
        <DeleteAddressModal
          currentAddress={addressToDelete!}
          isOpen={deleteAddressModalIsOpen}
          onClose={() => {
            setDeleteAddressModalIsOpen(false)
          }}
          onAccept={async id => {
            await deleteAddress(id)
            setDeleteAddressModalIsOpen(false)
          }}
        />
      )}
    </>
  )
}

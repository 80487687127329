import React from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'
import classNames from 'classnames'

import styles from './Chart.module.scss'
import { ChartDataPoint, ChartOption } from '../types'
import { MetricType } from 'graphql/__generated__/types'
import { roundNumber } from 'shared/core/utils'
import { metricLabels } from 'shared/domain/metric'

export interface Props {
  data: ChartDataPoint[]
  metric?: MetricType
  options: ChartOption[]
  lineType?: any
  strokeWidth?: number
  className?: string
}

export default function Chart(props: Props) {
  const metricText = props?.metric ? { value: metricLabels[props?.metric], angle: -90, dx: -20 } : undefined
  const rightOptions = props.options.filter(element => element.right)

  return (
    <div className={classNames(props.className, styles.chartWrapper)} data-testid="container-graph">
      <ResponsiveContainer width="100%" height={309}>
        <LineChart
          data={props.data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="interval" />
          <YAxis label={metricText} />
          <YAxis type="number" orientation="right" yAxisId={1} />
          <Tooltip
            formatter={(value: string, name: string) => {
              // convert name from camelCase to Title Case
              const formattedValue = typeof value === 'number' ? roundNumber(value) : value
              return [formattedValue, name]
            }}
            separator=": "
          />
          {props.options.map((val, i) => {
            const dotStroke = props.lineType ? { stroke: val.color, fill: val.color } : { stroke: val.color }
            return rightOptions.length === 0 || rightOptions.length === props.options.length || i > 0 ? (
              <Line
                key={i}
                type={props.lineType || 'monotone'}
                dataKey={val.name}
                stroke={val.color}
                strokeWidth={props.strokeWidth || 1}
                isAnimationActive={false}
                dot={dotStroke}
              />
            ) : (
              <Line
                key={i}
                type={props.lineType || 'monotone'}
                dataKey={val.name}
                stroke={val.color}
                strokeWidth={props.strokeWidth || 1}
                isAnimationActive={false}
                dot={dotStroke}
                yAxisId={1}
              />
            )
          })}
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}

import { useAlerts } from '../useAlerts'
import { useMutation } from '@apollo/client'
import {
  DeleteAddressDocument,
  DeleteAddressResult,
  MutationDeleteAddressArgs,
} from '../../../graphql/__generated__/types'


export const useDeleteAddress = () => {
  const { showSuccess, showError } = useAlerts()
  const [deleteAddressMut, { loading: deletingAddress }] = useMutation<
    DeleteAddressResult,
    MutationDeleteAddressArgs
  >(DeleteAddressDocument, {
    onCompleted: () => showSuccess('Address was deleted'),
    onError: error => {
      showError('There was a problem deleting the address. Try again later.')
      throw error
    },
  })
  return {
    deleteAddress: async (id: number) =>
      deleteAddressMut({
        variables: {
          options: { addressId: id },
        },
      }),
    deletingAddress,
  }
}

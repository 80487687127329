import { useApolloClient } from '@apollo/client'
import { GQLDoc } from 'shared/types/GQLDoc'

const defaultConfig = {
  numRecords: 500,
}

export function useGetAllItems<Result, Item, Vars = Object>() {
  const { query: apolloQuery } = useApolloClient()

  return async (
    query: GQLDoc,
    searchOptions: Vars,
    totalItems: number,
    resultMapper: (data: Result | undefined) => Item[],
  ) => {
    const { numRecords } = defaultConfig
    const queryOptions = {  where: {}, ...searchOptions, limit: numRecords }
    const numPages = Math.ceil(totalItems / numRecords)

    const queries = Array.from(Array(numPages).keys()).map(page =>
      apolloQuery<Result, Vars>({
        query,
        variables: {
          ...queryOptions,
          offset: queryOptions.limit * page,
        },
      }),
    )
    const results = await Promise.all(queries)
    return results.map(result => resultMapper(result?.data)).flat()
  }
}
